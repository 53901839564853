@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

/* nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
  border-bottom: 3px solid #d1d1d1;
  background-color: #ffffff;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
} */

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track:hover {
  background: #555;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}