.file-uploader {
    border: 1px solid #ced4da;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
}

.file-div {
    display: flex;
    width: 85%;
}

.file-div>button {
    justify-content: left;
    color: #ced4da;
}

/* .disabled {
    background-color: #e9ecef;
  } */

.file-name {
    margin-left: 0.5rem;
    color: #ced4da;
}

.attachment-icon {
    width: 100%;
    color: #ced4da;
}