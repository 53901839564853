.log-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 75px;
    border-bottom: 3px solid #d1d1d1;
    background-color: #ffffff;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}

/* display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 75px;
    background-color: transparent;
    background-color: white;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    width: 100%; */


.log-lab {
    font-family: "poppins";
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
    font-weight: 500;
    color: #010101;
}

.log-email {
    border: 0.75px solid #ccc9c9;
    border-radius: 5px;
    padding: 0.65rem;
    background-color: #e9eef1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1.5px rgba(0, 0, 0, 0.24);
    margin-bottom: 1.25rem;
}

.log-pass {
    border: none;
    background-color: inherit;
    width: 100%;
}

.log-lab {
    font-family: "poppins";
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
    font-weight: 500;
}

.pass-div {
    border: 0.75px solid #ccc9c9;
    border-radius: 5px;
    padding: 0.65rem;
    background-color: #e9eef1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1.5px rgba(0, 0, 0, 0.24);
    margin-bottom: 1.15rem;
    display: flex;
}

.loga-head {
    color: #10069b;
    text-align: right;
    margin-bottom: 2rem;
}


input[type=text].active {
    border: 0px solid #E0E0E0;
}

input[type=email]:focus {
    /* border: 0px; */
    outline: none !important;
}

input[type=file] {
    width: 100%;
    height: inherit;
    background-color: red;
    display: none;
}

input[type=submit] {
    margin-top: 0.8rem;
    background-color: #244886;
    border-style: none;
    height: 3rem;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
}

.pass-div img {
    width: 10%;
    max-width: 10%;
    height: 1.25rem;
}


.nav-logo-container {
    width: 14%;
    max-width: 15%;
    display: flex;
}

.contact {
    width: 18%;
    max-width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-button {
    padding: 0.9rem 1.2rem;
    background-color: white;
    color: #010101;
    border: 0.75px solid #0E0E0E;
    border-radius: 5px;
    font-size: 1.13rem;
    cursor: pointer;
    font-weight: 500;
    transition: 0.2s;
    height: 3rem;
    display: flex;
    white-space: nowrap;
}

.footer-contents {
    min-height: 75vh;
    background-color: #244886;
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}



.login-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 91.5%;
    max-width: 100%;
    background-color: #e6eaf0;
    margin: 2rem auto;
    min-height: 80vh;
    border-radius: 10px;
    justify-content: flex-start;
    padding: 3rem;
}

.login-container {
    background-color: #f6f8fa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    padding: 0;
}

.login-form {
    min-height: fit-content;
    width: 35%;
    max-width: 50%;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.login-heads {
    font-family: "IBM Plex Sans ";
    font-size: 1.12rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #010101;
}

.login-head {
    font-family: "IBM Plex Sans ";
    font-size: 1.85rem;
    margin-bottom: 3rem;
    color: #244886 !important;
}

.contact-button:hover {
    background-color: #376dca;
    color: #ffffff;
}

.contact-button-active {
    padding: 0.9rem 1.2rem;
    border: 0.75px solid #0E0E0E;
    border-radius: 5px;
    font-size: 1.13rem;
    cursor: pointer;
    font-weight: 500;
    transition: 0.2s;
    height: 3rem;
    display: flex;
    white-space: nowrap;
    background-color: #376dca;
    color: #ffffff;
}

.footer-rowone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 100%;
    margin-bottom: 8vh;
}

.rowone-a {
    width: 22%;
    max-width: 22%;
    display: flex;
}

.row-content {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
}

.row-content h3 {
    font-size: 1.32rem;
    font-family: 'Istok Web';
    color: #ffffff;
    font-weight: 600;
}

.row-content h4 {
    font-size: 0.92rem;
    font-family: 'Istok Web';
    color: #ffffff;
    font-weight: 300;
}

.rowone-b {
    width: 29%;
    max-width: 29%;
    display: flex;
}

.rowone-b {
    width: 29%;
    max-width: 29%;
    display: flex;
}

.footer-hr {
    width: 90%;
    border-bottom: 1px solid #ffffff;
}

.rowone-c {
    width: 43%;
    max-width: 43%;
    display: flex;
}

.io-mail {
    color: white;
    font-size: 2.25rem
}



.footer-rowtwo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 100%;
    margin-bottom: 5vh;
    margin-top: 5vh;
}


.rowtwo-a {
    width: 32%;
    max-width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 5rem;
}

.rowtwo-a p {
    color: #ffffff;
    margin-top: 0;
    font-family: "Istok Web";
    font-size: 1.053rem;
    font-weight: 300;
    text-align: left;
}

.rowtwo-background {
    height: 19vh;
    width: 100%;
    max-width: 100%;
    background-image: url("../../Assets/darklogo.png");
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 0;
    margin-bottom: 0;
}

.rowtwologos {
    display: flex;
    justify-content: flex-start;
    align-items: left;
    width: 100%;
    max-width: 100%;
    margin-top: 1.6rem;
}

.facebook {
    font-size: 2.25rem;
    color: #ffffff;
    margin-right: 1.25rem
}

.linkedin {
    font-size: 2.35rem;
    color: #ffffff;
    margin-right: 1.25rem
}

.twitter {
    font-size: 2rem;
    color: #ffffff;
    margin-right: 1.25rem
}

.whatsapp {
    font-size: 2.2rem;
    color: #ffffff;
    margin-right: 1.25rem
}

.rowtwo-b {
    width: 18%;
    max-width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    margin-right: 3.5rem;
}

.rowtwolist {
    list-style: none;
}

.rowsecondlist {
    list-style: none;
}

.rowsecondlist li {
    color: #ffffff;
    font-family: "Istok Web";
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    margin-bottom: 1.25rem;
    cursor: pointer;
}


.rowtwolist li {
    color: #ffffff;
    font-family: "Istok Web";
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    margin-bottom: 1.25rem;
    cursor: pointer;
}

.rowtwo-c {
    width: 30%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
}


@media screen and (max-width: 800px) {
    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 75px;
        border-bottom: 3px solid #d1d1d1;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .rowtwo-b {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
    }

    .rowtwo-c {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-top: 1rem;
    }

    .footer-rowtwo {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        max-width: 100%;
        margin-bottom: 5vh;
        margin-top: 1rem;
    }

    .rowtwologos {
        display: flex;
        justify-content: flex-start;
        align-items: left;
        width: 100%;
        max-width: 100%;
        margin-top: 1rem;
        margin-bottom: 2.25rem;
    }

    .facebook {
        font-size: 1.45rem;
    }

    .linkedin {
        font-size: 1.55rem;
    }

    .twitter {
        font-size: 1.2rem;
    }

    .whatsapp {
        font-size: 1.4rem;
    }

    .rowtwo-background {
        height: 10vh;
        width: 100%;
        max-width: 100%;
        background-position: center !important;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 1.5rem;
        margin-bottom: 0;
    }

    .rowtwo-a {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        margin-right: 0rem;
        margin-top: 0;
        margin-bottom: 2rem;
    }

    .rowtwo-a p {
        font-size: .9rem
    }


    .io-mail {
        font-size: 1rem;
    }

    .footer-hr {
        display: none;
    }

    .contact {
        display: none;
    }

    input[type=submit] {
        margin-top: 0.8rem;
        background-color: #244886;
        border-style: none;
        height: 3rem;
        color: #ffffff;
        font-size: 1rem;
        font-weight: 500;
    }

    .nav-logo-container {
        width: 22%;
        max-width: 24%;
    }

    .footer-contents {
        min-height: 100vh;
        background-color: #244886;
        padding-top: 2.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .footer-rowone {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 95%;
        max-width: 100%;
        margin-bottom: 0rem;
    }

    .rowone-a {
        width: 100%;
        max-width: 100%;
        display: flex;
        margin-bottom: 1rem;
    }

    .rowone-b {
        width: 100%;
        max-width: 100%;
        display: flex;
        margin-bottom: 1rem;
    }

    .rowone-c {
        width: 100%;
        max-width: 100%;
        display: flex;
    }
}